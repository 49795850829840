import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import arrowSvg from "../images/arrow-circle-right-solid.svg"
import liveTrackingImg from "../images/live-tracking-sq-small.jpg"

class HGS1ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="HGS1"
          description="View route information and buy your ticket for the HGS1 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">HGS1 service</h1>
              <p>Wigton Lane, Leeds - Heckmondwike grammar school, Wakefield</p>
              <p>Heckmondwike grammar school, Wakefield  - Wigton Lane, Leeds</p>
              <p style={{ margin: 0 }}>
                <a className='btn btn-grow btn-quote btn-ticket' href="https://passenger.shuttleid.uk/client/3108be57-5c93-4d16-84ff-0990d87eba95/7c4193ea-c5d8-4ea2-b851-b792804f2c8d">
                  <span className='btn-quote__text'>Buy ticket</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
                <a
                  style={{ display: "inline-block", marginLeft: 15 }}
                  href="https://passenger.shuttleid.uk"
                >
                  View My Tickets
                </a>
              </p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <p style={{ margin: 0 }}>
                  <img src={liveTrackingImg} alt="" />
                </p>
              </div>
              <div className="page-content__text">
                <h2 style={{ marginBottom: 15 }}>NEW: Live vehicle tracking</h2>
                <p>Users of the HGS1 service will be able to access live vehicle tracking directly from their ticket, starting January 2022.</p>
                <p>Our new "track bus" feature will allow students and parents to stay informed in real-time, and will complement our existing SMS service alerts, to offer the safest travel experience possible for your child.</p>
              </div>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="HGS1 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1N6dvB_9RaZrHs3Wofs3CMTDjL-f6eeE&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Heckmondwike grammar school, Wakefield
                </p>
                <p>
                  Wigton Lane, Shadwell Lane Stop ID: 45010231, Ring Road, King Lane, First Left into Moor Allerton District shopping Centre. (Second pick up is at the bus stop in the centre), Ring road, King Lane, Scott Hall Road, Clay Pit Lane, A58 inner ring road, A643, M621, Exit Jct 27 Birstall, Geldard Road, Huddersfield Road, White Lee Road, West Park Road, Halifax Road, Heckmondwike Grammar.{" "}
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="HGS1 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1BjkqomEv-6PKTJjeQs2i3XnCmueU_zg&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Heckmondwike grammar school, Wakefield
                </p>
                <p>
                  Halifax Road, West Park Road, White Lee Road, Huddersfield Road, Geldard Road, M621 City Centre bound, Exit Jct 2 A643, A58 Inner Ring Road, Clay pit Lane, Scott Hall Road, King Lane, First Left into Moor Allerton District shopping Centre. (Drop at the bus stop in the centre), Ring Road, Shadwell Lane, Wigton Lane.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Wigton Lane</td>
                      <td>DEP</td>
                      <td>0705</td>
                    </tr>
                    <tr>
                      <td>Shadwell Lane</td>
                      <td>DEP</td>
                      <td>0715</td>
                    </tr>
                    <tr>
                      <td>Moor Allerton District Centre</td>
                      <td>DEP</td>
                      <td>0730</td>
                    </tr>
                    <tr>
                      <td>Heckmondwike Grammar</td>
                      <td>ARR</td>
                      <td>0830</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Heckmondwike Grammar</td>
                      <td>DEP</td>
                      <td>1535</td>
                    </tr>
                    <tr>
                      <td>Moor Allerton District Centre</td>
                      <td>DEP</td>
                      <td>1615</td>
                    </tr>
                    <tr>
                      <td>Shadwell Lane</td>
                      <td>DEP</td>
                      <td>1630</td>
                    </tr>
                    <tr>
                      <td>Wigton Lane</td>
                      <td>ARR</td>
                      <td>1635</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default HGS1ServicePage
